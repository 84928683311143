import type { CampaignRegionConfig } from "@/components/pages/campaign/regionConfig/CampaignRegionConfigTypes";
import { useTargetingKeywordHistory } from "@/queries/keywords";
import { selectBudgetPacing, type BudgetPacing } from "@/selectors/budgetSelectors";
import { getAllocatedBudget } from "@/utilities/budget";
import type { DemographicsData } from "@/utilities/demographics";
import type {
    EventKeywordReport,
    EventKeywordSummaryReport,
    EventReport,
    EventSummaryReport,
    EventsList,
    TemporalEventReport,
} from "@/utilities/events";
import type {
    KeywordMetricsReportDetails,
    MetricsReport,
    PurposeMetricsReport,
    RegionsReport,
    TemporalKeywordMetricsReport,
    TemporalMetricsReport,
} from "@/utilities/metrics";
import { aggregateAsyncData } from "@/utilities/requests";
import isNil from "lodash/isNil";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import { type Action } from "../../../actions/Action";
import {
    campaignActions,
    campaignFundsActions,
    campaignSynchronisationActions,
    getActiveBudgetPlanAction,
    getCampaignAppleStatusAction,
    getChannelAction,
    getIOSAppAction,
    getIOSAppProductPagesAction,
    listAllAllocationsAction,
    listBudgetPlansAction,
    listCampaignHistoryRecords,
    listEventsAction,
    listRegionsAction,
} from "../../../actions/campaignActions";
import { channelConfigActions } from "../../../actions/configActions";
import {
    negativeKeywordsActions,
    seedKeywordsActions,
    targetingKeywordsActions,
} from "../../../actions/keywordsActions";
import {
    getEventListSummaryAction,
    getEventsKeywordsAction,
    getEventsSeriesAction,
    getEventsSummaryAction,
    getKeywordRanksSeriesAction,
    getKeywordSeriesAction,
    getKeywordSummaryAction,
    getKeywordsSummaryAction,
    getMetricsSeriesAction,
    getMetricsSummaryAction,
    getPurposeSummariesAction,
    getRegionsReportAction,
    getSearchTermsSummaryAction,
} from "../../../actions/reportingActions";
import { campaignRulesActions, keywordRulesActions, regionRulesActions } from "../../../actions/rulesActions";
import { teamOrganisationActions } from "../../../actions/teamActions";
import {
    type AsyncData,
    type EventAsyncData,
    type KeywordRankingAsyncData,
    type KeywordReport,
    type KeywordReportAsyncData,
    type ReportAsyncData,
    type State,
    type TimeRange,
} from "../../../reducers/domain";
import { initialAsyncDataState } from "../../../reducers/requestReducer";
import {
    selectActiveCountries,
    selectApp,
    selectCampaign,
    selectCampaignActiveBudget,
    selectCampaignAllocations,
    selectCampaignBudgetPlans,
    selectCampaignEvents,
    selectCampaignHistoryRecords,
    selectChannel,
    selectFunds,
    selectRegions,
} from "../../../selectors/campaignSelectors";
import {
    selectCampaignDemographics,
    selectCampaignProductPages,
    selectCampaignRegionConfig,
    selectChannelNames,
} from "../../../selectors/configSelectors";
import {
    selectKeywordTags,
    selectNegativeKeywords,
    selectSeedKeywords,
    selectTargetingKeywords,
    toTargetingKeyword,
    type TargetingKeyword,
} from "../../../selectors/keywordsSelectors";
import {
    selectCampaignRegionsSummary,
    selectCampaignSeries,
    selectCampaignSummary,
    selectEventKeywordSummary,
    selectEventKeywords,
    selectEventListSummary,
    selectEventSeries,
    selectEventSummary,
    selectEventsSeries,
    selectKeywordRankSeries,
    selectKeywordReport,
    selectKeywordSeries,
    selectKeywordsSummary,
    selectKeywordsSummaryDetails,
    selectPurposeSummaries,
    selectSearchTermsSummary,
    type TemporalNamedEventReport,
} from "../../../selectors/reportsSelectors";
import { selectCampaignRules, selectKeywordRules, selectRegionRules } from "../../../selectors/rulesSelectors";
import { selectTeam, selectTeamOrganisations } from "../../../selectors/teamSelectors";
import {
    type RubyBudgetAllocation,
    type RubyBudgetPlan,
    type RubyCampaign,
    type RubyCampaignAppleStatus,
    type RubyCampaignId,
    type RubyCampaignRecord,
    type RubyCampaignRule,
    type RubyCampaignSynchronisation,
    type RubyChannel,
    type RubyChannelNames,
    type RubyCountry,
    type RubyCurrency,
    type RubyEventTimestamp,
    type RubyIOSApp,
    type RubyKeyword,
    type RubyKeywordId,
    type RubyKeywordRankReport,
    type RubyKeywordRule,
    type RubyMetricsReportGrouping,
    type RubyProductPage,
    type RubyPurpose,
    type RubyRegion,
    type RubyRegionRule,
    type RubyTargetingKeywordBidsInfo,
    type RubyTargetingKeywordHistory,
    type RubyTeam,
    type RubyTeamId,
    type RubyTeamOrganisation,
} from "../../../services/backend/RubyData";
import { maxDataAge } from "../../../utilities/saga";
import { getPreviousTimeFilter, type TimeFilter } from "../../../utilities/timeFilter";

export interface ICampaignDataLoader {
    useTeamId: () => RubyTeamId;
    useCampaignId: () => RubyCampaignId;

    useKeywordId: () => RubyKeywordId;
    useEvent: () => string;

    useMetricsFrom: () => number;
    useMetricsTo: () => number;
    usePreviousTimeFilter: () => TimeFilter;
    usePreviousMetricsFrom: () => number;
    usePreviousMetricsTo: () => number;
    useRegionFilter: () => RubyCountry[];
    useEventTimeFilter: () => RubyEventTimestamp;

    useCampaign: () => AsyncData<RubyCampaign>;
    useTeam: () => AsyncData<RubyTeam>;

    useChannel: () => AsyncData<RubyChannel>;
    useRegions: () => AsyncData<RubyRegion[]>;
    useApp: () => AsyncData<RubyIOSApp>;
    useFunds: () => AsyncData<number>;
    useCurrency: () => RubyCurrency;
    useCountries: () => AsyncData<{
        activeCountries: RubyCountry[];
        inactiveCountries: RubyCountry[];
    }>;

    useCampaignHistoryRecords: () => AsyncData<RubyCampaignRecord[]>;

    useOrganisation: () => AsyncData<RubyTeamOrganisation>;

    useBudgetPlans: () => AsyncData<RubyBudgetPlan[]>;
    useActiveBudget: () => AsyncData<RubyBudgetPlan>;
    useBudgetMetrics: () => AsyncData<MetricsReport>;
    useBudgetPacing: () => AsyncData<BudgetPacing>;
    useAllAllocations: () => AsyncData<RubyBudgetAllocation[]>;
    useAllocatedBudget: () => AsyncData<{
        allocatedBudget: number;
        unallocatedBudget: number;
    }>;

    useMetricsSummary: (dateRange?: TimeRange) => ReportAsyncData<MetricsReport>;
    usePreviousMetricsSummary: () => ReportAsyncData<MetricsReport>;
    useMetricsSeries: (dateRange?: TimeRange) => ReportAsyncData<TemporalMetricsReport[]>;

    usePurposeSummary: () => ReportAsyncData<PurposeMetricsReport[]>;

    useTargetingKeywords: (allStatuses?: boolean) => AsyncData<TargetingKeyword[]>;
    useAllTargetingKeywords: (regionFilter?: RubyCountry[], allStatuses?: boolean) => AsyncData<TargetingKeyword[]>;
    useNegativeKeywords: () => AsyncData<RubyKeyword[]>;
    useSeedKeywords: () => AsyncData<RubyKeyword[]>;

    useTargetingKeywordBidsInfo: () => AsyncData<RubyTargetingKeywordBidsInfo[]>;
    useTargetingKeywordHistory: () => AsyncData<RubyTargetingKeywordHistory[]>;
    useKeywordsSummary: () => ReportAsyncData<RubyMetricsReportGrouping[]>;
    useKeywordsSummaryDetails: () => ReportAsyncData<KeywordMetricsReportDetails[]>;

    useTargetingKeyword: (keywordId?: RubyKeywordId) => AsyncData<TargetingKeyword>;
    useKeywordSummary: (dateRange?: TimeRange) => KeywordReportAsyncData<KeywordReport>;
    usePreviousKeywordSummary: () => KeywordReportAsyncData<KeywordReport>;
    useKeywordSeries: () => KeywordReportAsyncData<TemporalKeywordMetricsReport[]>;
    useKeywordTags: () => AsyncData<string[]>;
    useKeywordRankingSeries: () => KeywordRankingAsyncData<RubyKeywordRankReport[]>;

    useSearchTermsSummary: (
        keywordId?: RubyKeywordId[],
        purpose?: RubyPurpose[]
    ) => ReportAsyncData<RubyMetricsReportGrouping[]>;

    useRegionsReport: () => ReportAsyncData<RegionsReport>;
    useRegionsBudgetPacing: () => ReportAsyncData<RegionsReport>;

    useEventsList: () => AsyncData<EventsList>;
    useEventListSummary: (dateRange?: TimeRange) => EventAsyncData<EventSummaryReport[]>;
    useAllTimeEventListSummary: () => EventAsyncData<EventSummaryReport[]>;
    usePreviousEventListSummary: () => EventAsyncData<EventSummaryReport[]>;
    useEventSummary: (eventName?: string) => EventAsyncData<EventReport>;
    usePreviousEventSummary: (eventName?: string) => EventAsyncData<EventReport>;
    useEventKeywords: (eventName?: string) => EventAsyncData<EventKeywordReport[]>;
    useEventKeywordsSummary: (eventName?: string) => EventAsyncData<EventKeywordSummaryReport[]>;
    useEventSeries: (eventName?: string) => EventAsyncData<TemporalEventReport[]>;
    useEventsSeries: () => AsyncData<TemporalNamedEventReport[][]>;

    useDemographicsData: () => AsyncData<DemographicsData>;
    useRegionConfig: () => AsyncData<CampaignRegionConfig.Data>;
    useProductPages: () => AsyncData<RubyProductPage[]>;
    useChannelNames: () => AsyncData<RubyChannelNames>;

    useExternalStatus: () => AsyncData<RubyCampaignAppleStatus>;
    useSynchronisation: () => AsyncData<RubyCampaignSynchronisation>;

    useCampaignRules: () => AsyncData<RubyCampaignRule[]>;
    useRegionRules: () => AsyncData<RubyRegionRule[]>;
    useKeywordRules: () => AsyncData<RubyKeywordRule[]>;
}

export function useCampaignDataLoader(campaignId?: RubyCampaignId, teamId?: RubyTeamId): ICampaignDataLoader {
    // const loader = useMemo<CampaignDataManager>(() => {
    //     const activeCampaignId = useSelector((state: State) => state.ui.activeCampaign);
    //     return new CampaignDataManager(campaignId ?? activeCampaignId, teamId);
    // }, [campaignId, teamId]);
    const activeCampaignId = useSelector((state: State) => state.ui.activeCampaign);
    return new CampaignDataLoader(campaignId ?? activeCampaignId, teamId);
}

class CampaignDataLoader implements ICampaignDataLoader {
    private _campaignId: RubyCampaignId;
    private _teamId?: RubyTeamId;
    private _dispatch: Dispatch;

    constructor(campaignId: RubyCampaignId, teamId?: RubyTeamId) {
        this._campaignId = campaignId;
        this._teamId = teamId;
        this._dispatch = useDispatch();
        return this;
    }

    private selector<T = unknown, Args extends unknown[] = []>(
        selector: (state: State, ...args: Args) => T,
        ...args: Args
    ): T {
        return useSelector((state: State) => selector(state, ...args));
    }

    private dispatch(a: Action<unknown, unknown>, request: AsyncData<unknown>, preReqs?: unknown[]) {
        // If any of the specified preReqs are missing don't request
        if (Array.isArray(preReqs) && preReqs.findIndex(isNil) !== -1) {
            return;
        }
        // If request not made, call it.
        else if (!request?.isRequesting && !request?.success && !request?.errorMessage) {
            console.log("CampaignDataLoader has no data, making initial request", request);
            return this._dispatch(a);
        }
        // If requested but out of date, re-call it
        else if (request?.success && request?.lastUpdated < maxDataAge()) {
            console.log("CampaignDataLoader has found expired data and is re-requesting", request);
            return this._dispatch(a);
        }
    }

    private loadAndSelect<T extends AsyncData<unknown> = AsyncData<unknown>>(
        selector: (state: State, campaignId: RubyCampaignId, teamId: RubyTeamId) => T,
        loader: (
            campaignId: RubyCampaignId,
            teamId: RubyTeamId
        ) => Action<unknown, unknown> | Action<unknown, unknown>[],
        requirements?: unknown[]
    ): T {
        const campaignId = this.useCampaignId();
        const teamId = this.useTeamId();
        const data = this.selector(selector, campaignId, teamId);
        let actions = loader(campaignId, teamId);
        if (!Array.isArray(actions)) {
            actions = [actions];
        }
        // If the campaignId or teamId are missing the page is likely still init-ing, so don't dispatch yet
        if (teamId && campaignId) {
            for (const action of actions) {
                this.dispatch(action, data, requirements);
            }
        }
        return data;
    }

    useCampaignId() {
        return this.selector((state) => this._campaignId ?? state.ui.activeCampaign);
    }

    useTeamId() {
        return this.selector((state) => this._teamId ?? state.ui.activeTeam);
    }

    useCampaign() {
        return this.loadAndSelect(selectCampaign, (campaignId, teamId) =>
            campaignActions.get.request({ teamId, campaignId })
        );
    }

    useTeam() {
        const teamId = this.useTeamId();
        return this.selector(selectTeam, teamId);
    }

    useKeywordId() {
        return this.selector((state) => state.ui.activeKeyword);
    }

    useEvent() {
        return this.selector((state) => state.ui.activeEvent);
    }

    useOrganisation() {
        const channel = this.useChannel();
        const orgs = this.loadAndSelect(
            (state, _campaignId, teamId) => selectTeamOrganisations(state, teamId),
            (_campaignId, teamId) => teamOrganisationActions.list.request({ teamId })
        );
        const org = orgs?.data?.find((org) => org.orgRef === channel.data?.orgRef);
        return {
            ...orgs,
            data: org ?? null,
        };
    }

    useCurrency() {
        return this.useTeam().data?.currency;
    }

    // Page Filters
    useMetricsFrom() {
        return this.selector((state) => state.campaigns.searchDates[0]);
    }
    useMetricsTo() {
        return this.selector((state) => state.campaigns.searchDates[1]);
    }

    usePreviousTimeFilter() {
        const metricsFrom = this.useMetricsFrom();
        const metricsTo = this.useMetricsTo();
        const campaign = this.useCampaign();
        return getPreviousTimeFilter(
            {
                description: "",
                from: moment.utc(metricsFrom),
                to: moment.utc(metricsTo),
            },
            campaign.data?.createdAt ?? 0
        );
    }

    usePreviousMetricsFrom() {
        const previousTimeFilter = this.usePreviousTimeFilter();
        return previousTimeFilter?.from?.valueOf() ?? null;
    }

    usePreviousMetricsTo() {
        const previousTimeFilter = this.usePreviousTimeFilter();
        return previousTimeFilter?.to?.valueOf() ?? null;
    }

    useRegionFilter() {
        return this.selector((state) => state.reports.regionFilter);
    }

    useEventTimeFilter() {
        return this.selector((state) => state.reports.eventTimeFilter);
    }

    useChannel() {
        const channelType = this.useCampaign().data?.channelType;
        return this.loadAndSelect(
            selectChannel,
            (campaignId, teamId) =>
                getChannelAction.request({
                    teamId,
                    campaignId,
                    channelType,
                }),
            [channelType]
        );
    }

    useRegions() {
        const channelType = this.useCampaign().data?.channelType;
        return this.loadAndSelect(
            selectRegions,
            (campaignId, teamId) =>
                listRegionsAction.request({
                    teamId,
                    campaignId,
                    channelType,
                }),
            [channelType]
        );
    }

    useCountries() {
        this.useCampaign();
        this.useChannel();
        this.useRegions();
        return this.selector(selectActiveCountries, this.useCampaignId());
    }

    useApp() {
        const channel = this.useChannel();
        return this.loadAndSelect(
            (state) => selectApp(state, channel.data?.applicationRef),
            (_, teamId) =>
                getIOSAppAction.request({
                    teamId,
                    appId: channel.data?.applicationRef,
                    countryCode: channel.data?.storefront,
                }),
            [channel.data?.applicationRef]
        );
    }

    useFunds() {
        return this.loadAndSelect(selectFunds, (campaignId, teamId) =>
            campaignFundsActions.get.request({ teamId, campaignId })
        );
    }

    useCampaignHistoryRecords() {
        const from = this.useMetricsFrom();
        const to = this.useMetricsTo();

        return this.loadAndSelect(
            (state, campaignId) =>
                selectCampaignHistoryRecords(state, {
                    campaignId,
                    from,
                    to,
                }),
            (campaignId) =>
                listCampaignHistoryRecords.request({
                    campaignId,
                    from,
                    to,
                }),
            [from, to]
        );
    }

    // Budgets
    useBudgetPlans() {
        return this.loadAndSelect(selectCampaignBudgetPlans, (campaignId) => listBudgetPlansAction.request(campaignId));
    }

    useActiveBudget() {
        return this.loadAndSelect(selectCampaignActiveBudget, (campaignId) =>
            getActiveBudgetPlanAction.request(campaignId)
        );
    }

    useBudgetMetrics() {
        const plan = this.useActiveBudget();
        const from = plan.data?.start;
        const to = plan.data?.end;
        return this.loadAndSelect(
            (state, campaignId, teamId) =>
                selectCampaignSummary(state, {
                    campaignId,
                    teamId,
                    from,
                    to,
                }),
            (campaignId, teamId) =>
                getMetricsSummaryAction.request({
                    teamId,
                    campaignId,
                    from,
                    to,
                }),
            [from, to]
        );
    }

    useBudgetPacing() {
        this.useBudgetMetrics();
        this.useActiveBudget();
        const campaignId = this.useCampaignId();
        return this.selector(selectBudgetPacing, campaignId);
    }

    useAllAllocations() {
        return this.loadAndSelect(selectCampaignAllocations, (campaignId) =>
            listAllAllocationsAction.request({
                campaignId,
            })
        );
    }

    useAllocatedBudget() {
        const budget = this.useActiveBudget();
        const allocatedBudget = getAllocatedBudget(budget?.data);
        const unallocatedBudget = (budget?.data?.totalBudget ?? 0) - (allocatedBudget ?? 0);
        return {
            ...budget,
            data: {
                allocatedBudget,
                unallocatedBudget,
            },
        };
    }

    // Metrics
    private useBuildMetricsQuery(dateRange?: TimeRange) {
        const metricsFrom = this.useMetricsFrom();
        const metricsTo = this.useMetricsTo();
        const from = dateRange?.[0] ?? metricsFrom;
        const to = dateRange?.[1] ?? metricsTo;
        const country = this.useRegionFilter();

        return (campaignId: RubyCampaignId, teamId: RubyTeamId) => ({
            campaignId,
            teamId,
            from,
            to,
            country,
        });
    }

    useMetricsSummary(dateRange?: TimeRange) {
        const buildQuery = this.useBuildMetricsQuery(dateRange);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectCampaignSummary(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getMetricsSummaryAction.request(buildQuery(campaignId, teamId))
        );
    }

    usePreviousMetricsSummary() {
        const from = this.usePreviousMetricsFrom();
        const to = this.usePreviousMetricsTo();
        return this.useMetricsSummary([from, to]);
    }

    useMetricsSeries(dateRange?: TimeRange) {
        const buildQuery = this.useBuildMetricsQuery(dateRange);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectCampaignSeries(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getMetricsSeriesAction.request(buildQuery(campaignId, teamId))
        );
    }

    usePurposeSummary() {
        const buildQuery = this.useBuildMetricsQuery();
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectPurposeSummaries(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getPurposeSummariesAction.request(buildQuery(campaignId, teamId))
        );
    }

    // Keywords
    useTargetingKeywords(allStatuses?: boolean) {
        const regionFiler = this.useRegionFilter();
        return this.useAllTargetingKeywords(regionFiler, allStatuses);
    }

    useAllTargetingKeywords(regionFilter?: RubyCountry[], allStatuses?: boolean) {
        return this.loadAndSelect(
            (state, campaignId) => selectTargetingKeywords(state, campaignId, regionFilter, allStatuses),
            (campaignId, teamId) =>
                targetingKeywordsActions.list.request({
                    campaignId,
                    teamId,
                })
        );
    }

    useNegativeKeywords() {
        return this.loadAndSelect(selectNegativeKeywords, (campaignId, teamId) =>
            negativeKeywordsActions.list.request({ teamId, campaignId })
        );
    }

    useSeedKeywords() {
        return this.loadAndSelect(selectSeedKeywords, (campaignId, teamId) =>
            seedKeywordsActions.list.request({ teamId, campaignId })
        );
    }

    useTargetingKeywordBidsInfo() {
        return this.loadAndSelect(
            (state, campaignId) => state.keywords.bidsInfo[campaignId] ?? initialAsyncDataState([]),
            (campaignId, teamId) => targetingKeywordsActions.listBidsInfo.request({ campaignId, teamId })
        );
    }

    useTargetingKeywordHistory() {
        return useTargetingKeywordHistory({
            teamId: this.useTeamId(),
            campaignId: this.useCampaignId(),
            keywordId: this.useKeywordId(),
            from: this.useMetricsFrom(),
            to: this.useMetricsTo(),
        });
    }

    useKeywordsSummary() {
        const buildQuery = this.useBuildMetricsQuery();
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectKeywordsSummary(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getKeywordsSummaryAction.request(buildQuery(campaignId, teamId))
        );
    }

    useKeywordsSummaryDetails() {
        this.useTargetingKeywords();
        this.useKeywordsSummary();
        const buildQuery = this.useBuildMetricsQuery();
        const campaignId = this.useCampaignId();
        const teamId = this.useTeamId();
        return this.selector(selectKeywordsSummaryDetails, buildQuery(campaignId, teamId));
    }

    // Single Keyword
    useTargetingKeyword(keywordId?: RubyKeywordId) {
        const allKeywords = this.useAllTargetingKeywords(undefined, true);
        const regions = this.useRegions();
        const req = aggregateAsyncData(allKeywords, regions);
        const stateKeywordId = this.useKeywordId();

        if (!req.success) {
            return { ...req, data: null };
        }

        const id = keywordId ?? stateKeywordId;
        const keyword = allKeywords.data.find((kw) => kw.id === id);
        const targetingKeyword = toTargetingKeyword(regions.data)(keyword);
        return {
            ...req,
            data: targetingKeyword,
        };
    }

    useKeywordSummary(dateRange?: TimeRange) {
        const keywordId = this.useKeywordId();
        const buildQuery = this.useBuildMetricsQuery(dateRange);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectKeywordReport(state, { ...buildQuery(campaignId, teamId), keywordId }),
            (campaignId, teamId) => getKeywordSummaryAction.request({ ...buildQuery(campaignId, teamId), keywordId })
        );
    }

    usePreviousKeywordSummary() {
        const from = this.usePreviousMetricsFrom();
        const to = this.usePreviousMetricsTo();
        return this.useKeywordSummary([from, to]);
    }

    useKeywordSeries() {
        const keywordId = this.useKeywordId();
        const buildQuery = this.useBuildMetricsQuery();
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectKeywordSeries(state, { ...buildQuery(campaignId, teamId), keywordId }),
            (campaignId, teamId) => getKeywordSeriesAction.request({ ...buildQuery(campaignId, teamId), keywordId })
        );
    }

    useKeywordTags() {
        const campaignId = this.useCampaignId();
        this.useTargetingKeywords();
        return this.selector(selectKeywordTags, campaignId);
    }

    useKeywordRankingSeries() {
        const campaignId = this.useCampaignId();
        const teamId = this.useTeamId();
        const app = this.useApp();
        const keyword = this.useTargetingKeyword();
        const buildQuery = this.useBuildMetricsQuery();
        const request = {
            ...buildQuery(campaignId, teamId),
            adamRef: app.data?.trackId,
            searchTerm: keyword?.data?.text,
        };
        return this.loadAndSelect(
            (state) => selectKeywordRankSeries(state, request),
            () => getKeywordRanksSeriesAction.request(request)
        );
    }

    useSearchTermsSummary(keywordId?: RubyKeywordId[], purpose?: RubyPurpose[]) {
        const buildQuery = this.useBuildMetricsQuery();
        return this.loadAndSelect(
            (state, campaignId, teamId) =>
                selectSearchTermsSummary(state, {
                    ...buildQuery(campaignId, teamId),
                    keywordId,
                    purpose,
                }),
            (campaignId, teamId) =>
                getSearchTermsSummaryAction.request({
                    ...buildQuery(campaignId, teamId),
                    keywordId,
                    purpose,
                })
        );
    }

    // Regions
    useRegionsReport(dateRange?: TimeRange) {
        const request = {
            campaignId: this.useCampaignId(),
            teamId: this.useTeamId(),
            from: this.useMetricsFrom(),
            to: this.useMetricsTo(),
        };
        if (dateRange) {
            request.from = dateRange[0];
            request.to = dateRange[1];
        }
        return this.loadAndSelect(
            (state) => selectCampaignRegionsSummary(state, request),
            () => getRegionsReportAction.request(request)
        );
    }

    useRegionsBudgetPacing() {
        const budget = this.useActiveBudget();
        return this.useRegionsReport([budget.data?.start, budget.data?.end]);
    }

    // Events
    private useBuildEventQuery(event?: string | null, dateRange?: TimeRange) {
        const buildQuery = this.useBuildMetricsQuery(dateRange);
        const eventName = this.useEvent();
        const timestampKey = this.useEventTimeFilter();
        return (campaignId: RubyCampaignId, teamId: RubyTeamId) => ({
            ...buildQuery(campaignId, teamId),
            timestampKey,
            name: event === null ? null : event ?? eventName,
        });
    }

    useEventsList() {
        return this.loadAndSelect(selectCampaignEvents, (campaignId, teamId) =>
            listEventsAction.request({ campaignId, teamId })
        );
    }

    useEventListSummary(dateRange?: TimeRange) {
        const buildQuery = this.useBuildEventQuery(null, dateRange);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectEventListSummary(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getEventListSummaryAction.request(buildQuery(campaignId, teamId))
        );
    }

    usePreviousEventListSummary() {
        const from = this.usePreviousMetricsFrom();
        const to = this.usePreviousMetricsTo();
        return this.useEventListSummary([from, to]);
    }

    useAllTimeEventListSummary() {
        const campaignId = this.useCampaignId();
        const teamId = this.useTeamId();
        const request = {
            campaignId,
            teamId,
            country: this.useRegionFilter(),
            name: null,
            timestampKey: this.useEventTimeFilter(),
        };
        return this.loadAndSelect(
            (state) => selectEventListSummary(state, request),
            () => getEventListSummaryAction.request(request)
        );
    }

    useEventSummary(event?: string, dateRange?: TimeRange) {
        const buildQuery = this.useBuildEventQuery(event, dateRange);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectEventSummary(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getEventsSummaryAction.request(buildQuery(campaignId, teamId))
        );
    }

    usePreviousEventSummary(event?: string) {
        const from = this.usePreviousMetricsFrom();
        const to = this.usePreviousMetricsTo();
        return this.useEventSummary(event, [from, to]);
    }

    useEventKeywords(event?: string) {
        const buildQuery = this.useBuildEventQuery(event);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectEventKeywords(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getEventsKeywordsAction.request(buildQuery(campaignId, teamId))
        );
    }

    useEventKeywordsSummary(event?: string) {
        const campaignId = this.useCampaignId();
        const teamId = this.useTeamId();
        const buildQuery = this.useBuildEventQuery(event);
        this.useEventKeywords();
        this.useKeywordsSummaryDetails();
        return this.selector(selectEventKeywordSummary, buildQuery(campaignId, teamId));
    }

    useEventSeries(event?: string) {
        const buildQuery = this.useBuildEventQuery(event);
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectEventSeries(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => getEventsSeriesAction.request(buildQuery(campaignId, teamId))
        );
    }

    useEventsSeries() {
        const buildQuery = this.useBuildEventQuery(null);
        const events = this.useEventsList();
        return this.loadAndSelect(
            (state, campaignId, teamId) => selectEventsSeries(state, buildQuery(campaignId, teamId)),
            (campaignId, teamId) => {
                if (!events.success) {
                    return [];
                }
                return events.data.map((event) =>
                    getEventsSeriesAction.request({ ...buildQuery(campaignId, teamId), name: event })
                );
            }
        );
    }

    //Config
    useDemographicsData() {
        this.useCampaign();
        this.useChannel();
        this.useRegions();
        this.useBudgetPlans();
        this.useApp();
        const campaignId = this.useCampaignId();
        return this.selector(selectCampaignDemographics, campaignId);
    }

    useRegionConfig() {
        this.useCampaign();
        this.useChannel();
        this.useRegions();
        this.useBudgetPlans();
        this.useApp();
        this.useRegionsBudgetPacing();
        const campaignId = this.useCampaignId();
        return this.selector(selectCampaignRegionConfig, campaignId);
    }

    useProductPages() {
        const channel = this.useChannel();
        return this.loadAndSelect(
            (state) => selectCampaignProductPages(state, channel.data?.applicationRef),
            (campaignId, teamId) =>
                getIOSAppProductPagesAction.request({
                    campaignId,
                    teamId,
                    appId: channel.data?.applicationRef,
                }),
            [channel.data?.applicationRef]
        );
    }

    useChannelNames() {
        const campaign = this.useCampaign();
        return this.loadAndSelect(
            selectChannelNames,
            (campaignId, teamId) =>
                channelConfigActions.getChannelNamesAction.request({
                    campaignId,
                    teamId,
                    channelType: campaign.data?.channelType,
                }),
            [campaign.data?.channelType]
        );
    }

    // Sync

    useExternalStatus() {
        return this.loadAndSelect(
            (state, campaignId) => state.campaigns.appleStatus[campaignId] ?? initialAsyncDataState(),
            (campaignId, teamId) => getCampaignAppleStatusAction.request({ teamId, campaignId })
        );
    }

    useSynchronisation() {
        const campaign = this.useCampaign();
        return this.loadAndSelect(
            (state, campaignId) => state.campaigns.synchronisation[campaignId] ?? initialAsyncDataState(),
            (campaignId, teamId) =>
                campaignSynchronisationActions.get.request({
                    teamId,
                    campaignId,
                    channelType: campaign.data?.channelType,
                }),
            [campaign.data?.channelType]
        );
    }

    // Rules
    useCampaignRules() {
        return this.loadAndSelect(selectCampaignRules, (campaignId, teamId) =>
            campaignRulesActions.list.request({ teamId, campaignId })
        );
    }

    useRegionRules() {
        return this.loadAndSelect(selectRegionRules, (campaignId, teamId) =>
            regionRulesActions.list.request({ teamId, campaignId })
        );
    }

    useKeywordRules() {
        return this.loadAndSelect(selectKeywordRules, (campaignId, teamId) =>
            keywordRulesActions.list.request({ teamId, campaignId })
        );
    }
}
