import type { CampaignObjective } from "@/types/CampaignObjective";
import type { ReportGranularity } from "@/utilities/time";
import {
    RuleAction,
    RuleCondition,
    RuleProperty,
    RuleType,
    type AllocatedBudgetPlanResponse,
    type AppleApplication,
    type AppleCampaignsWithOrg,
    type AppleChannelSynchronisationResponse,
    type AppleProxyProductPageDetail,
    type AppleProxyProductPageLocaleDetail,
    type AppleiTunesSearchResult,
    type AsrTargetingKeywordHistoryResponse,
    type AttributionEventGroupingResponse,
    type AttributionEventSummaryQuery,
    type AttributionEventSummaryResponse,
    type Calendar,
    type CalendarEvent,
    type CampaignRecord,
    type CampaignRuleResponse,
    type CountsResponse,
    type CreateAppleProductPageBrowseRegionBody,
    type CreateAppleProductPageBrowseRegionResponse,
    type CreateAppleSearchResultsRegionBody,
    type CreateAppleSearchResultsRegionResponse,
    type CreateAppleSearchTabsRegionBody,
    type CreateAppleSearchTabsRegionResponse,
    type CreateAppleTodayTabRegionBody,
    type CreateAppleTodayTabRegionResponse,
    type CreateInvitationBody,
    type CreatePrepayOrderResponse,
    type CreateRuleBody,
    type CreateTeamBody,
    type CreateTierBody,
    type DripBudgetBody,
    type EgressTeamMessage,
    type GetAppleProductPageBrowseChannelResponse,
    type GetAppleProductPageBrowseRegionResponse,
    type GetAppleSearchResultsChannelResponse,
    type GetAppleSearchResultsRegionResponse,
    type GetAppleSearchTabsChannelResponse,
    type GetAppleSearchTabsRegionResponse,
    type GetAppleStatusResponse,
    type GetAppleTargetingKeywordResponse,
    type GetAppleTodayTabChannelResponse,
    type GetAppleTodayTabRegionResponse,
    type GetCampaignResponse,
    type GetInvitationResponse,
    type GetMeResponse,
    type GetMemberResponse,
    type GetNotificationMessageResponse,
    type GetStripeCustomerDetailResponse,
    type GetTeamResponse,
    type GetTierResponse,
    type GetUserNotificationSettingsResponse,
    type GetUserResponse,
    type ImportStandaloneCampaignBody,
    type ImportStandaloneCampaignResponse,
    type KeywordRuleResponse,
    type ListAsrAppleCampaignNamesResponse,
    type ListAstAppleCampaignNamesResponse,
    type ListAttAppleCampaignNamesResponse,
    type ListBudgetAllocationsResponse,
    type ListCampaignRecordQuery,
    type ListPpbAppleCampaignNamesResponse,
    type ListResponse,
    type ListTeamOrganisationsResponse,
    type ListTeamRecordQuery,
    type ListUserRecordQuery,
    type OpenAIChatCompletionRequest,
    type Purpose,
    type RegionRuleResponse,
    type ReplicaAdGroupTargetingKeywordBidsInfo,
    type ReplicaCampaignReport,
    type ReplicaCampaignReportGroupingQuery,
    type ReplicaCampaignReportSummaryQuery,
    type ReplicaCustomReportQuery,
    type ReplicaCustomReportResponse,
    type ReplicaReportGrouping,
    type ReplicaReportGroupingResponse,
    type ReplicaReportSummaryResponse,
    type ReplicaSearchTermReportSummaryQuery,
    type SortedCampaignListType,
    type TeamRecord,
    type UpdateAppleProductPageBrowseChannelBody,
    type UpdateAppleProductPageBrowseChannelResponse,
    type UpdateAppleProductPageBrowseRegionBody,
    type UpdateAppleProductPageBrowseRegionResponse,
    type UpdateAppleSearchResultsChannelBody,
    type UpdateAppleSearchResultsChannelResponse,
    type UpdateAppleSearchResultsRegionBody,
    type UpdateAppleSearchResultsRegionResponse,
    type UpdateAppleSearchTabsChannelBody,
    type UpdateAppleSearchTabsChannelResponse,
    type UpdateAppleSearchTabsRegionBody,
    type UpdateAppleSearchTabsRegionResponse,
    type UpdateAppleTodayTabChannelBody,
    type UpdateAppleTodayTabChannelResponse,
    type UpdateAppleTodayTabRegionBody,
    type UpdateAppleTodayTabRegionResponse,
    type UpdateAsrAppleCampaignNamesBody,
    type UpdateAstAppleCampaignNamesBody,
    type UpdateAttAppleCampaignNamesBody,
    type UpdateDefaultStripePaymentMethodResponse,
    type UpdateMemberBody,
    type UpdatePpbAppleCampaignNamesBody,
    type UpdateRulePriorityBody,
    type UpdateTeamBody,
    type UserRecord,
} from "@redbox-ruby/data-lib";

export type RubyUser = GetUserResponse;
export interface RubyUserMe extends GetMeResponse {
    metadata: Record<string, unknown>;
    emailVerified: boolean;
    authId: string;
}
export {
    KeywordBidStrategy as RubyBidStrategy,
    BudgetPlanMethod as RubyBudgetPlanMethod,
    BudgetPlanPolicy as RubyBudgetPlanPolicy,
    BudgetPlanStatus as RubyBudgetPlanStatus,
    CampaignStatus as RubyCampaignStatus,
    AppleChannelStatus as RubyChannelStatus,
    ChannelStructure as RubyChannelStructure,
    ChannelType as RubyChannelType,
    OpenAIChatCompletionRequestMessageRole as RubyChatRole,
    ClientType as RubyClientType,
    HistoryRecordType as RubyHistoryRecordType,
    KeywordBidStrategy as RubyKeywordBidStrategy,
    KeywordMatchType as RubyKeywordMatchType,
    ReplicaKeywordRank as RubyKeywordRank,
    KeywordSource as RubyKeywordSource,
    AppleChannelKeywordStatus as RubyKeywordStatus,
    NotificationType as RubyNotificationType,
    PrepayPaymentStatus as RubyOrderPaymentStatus,
    OrderStatus as RubyOrderStatus,
    PaymentProcessorType as RubyPaymentProcessor,
    PaymentModelType as RubyPaymentType,
    Purpose as RubyPurpose,
    AppleChannelRegionStatus as RubyRegionStatus,
    RuleScope as RubyRuleScope,
    RuleStatus as RubyRuleStatus,
    ScheduledChangeStatus as RubyScheduledChangeStatus,
    TeamRole as RubyTeamRole,
    TeamStatus as RubyTeamStatus,
    TierStatus as RubyTierStatus,
    UserRole as RubyUserRole,
} from "@redbox-ruby/data-lib";
export type { AttributionEventTimestampKey as RubyEventTimestamp } from "@redbox-ruby/data-lib";

export type RubyListResponse<T> = ListResponse<T>;

export interface RubyTeam extends GetTeamResponse {
    tier: RubyTier;
}
export type RubyTeamId = RubyTeam["id"];
export interface RubyCreateTeamBody {
    team: CreateTeamBody;
    paymentDetails: RubyStripeCustomer;
}

export type RubyCalendar = Calendar;
export type RubyCalendarEvent = CalendarEvent;
export interface RubyCalendarWithEvents extends RubyCalendar {
    events: RubyCalendarEvent[];
}

export type RubyUpdateTeamBody = UpdateTeamBody;

export type RubyTier = GetTierResponse;
export type RubyCreateTierBody = CreateTierBody;

export type RubyNotification = GetNotificationMessageResponse;

export type RubyTeamMember = GetMemberResponse;
export type RubyUpdateTeamMemberBody = UpdateMemberBody;

export type RubyTeamInvite = GetInvitationResponse;
export interface RubyCreateTeamInvite extends CreateInvitationBody {
    teamId: RubyTeamId;
}

export type RubyStripeCustomer = GetStripeCustomerDetailResponse;

export type RubyTeamOrganisation = ListTeamOrganisationsResponse["results"][number];

export interface RubyCampaign extends Omit<GetCampaignResponse, "objective"> {
    teamId: RubyTeamId;
    objective?: CampaignObjective;
}
export type RubyCampaignId = RubyCampaign["id"];

export type RubyASRChannel = GetAppleSearchResultsChannelResponse;
export type RubyASTChannel = GetAppleSearchTabsChannelResponse;
export type RubyATTChannel = GetAppleTodayTabChannelResponse;
export type RubyPPBChannel = GetAppleProductPageBrowseChannelResponse;
export type RubyChannel = RubyASRChannel | RubyASTChannel | RubyATTChannel | RubyPPBChannel;

export type RubyUpdateASRChannelBody = UpdateAppleSearchResultsChannelBody;
export type RubyUpdateASTChannelBody = UpdateAppleSearchTabsChannelBody;
export type RubyUpdateATTChannelBody = UpdateAppleTodayTabChannelBody;
export type RubyUpdatePPBChannelBody = UpdateAppleProductPageBrowseChannelBody;
export type RubyChannelUpdateBody =
    | RubyUpdateASRChannelBody
    | RubyUpdateASTChannelBody
    | RubyUpdateATTChannelBody
    | RubyUpdatePPBChannelBody;

export interface RubyChannelNamesQuery {
    teamId: RubyTeamId;
    campaignId: RubyCampaignId;
    channelType: RubyCampaign["channelType"];
}
export type RubyChannelUpdateNamesBody =
    | UpdateAsrAppleCampaignNamesBody
    | UpdateAstAppleCampaignNamesBody
    | UpdateAttAppleCampaignNamesBody
    | UpdatePpbAppleCampaignNamesBody;
export type RubyChannelNames =
    | ListAsrAppleCampaignNamesResponse["results"]
    | ListAstAppleCampaignNamesResponse["results"]
    | ListAttAppleCampaignNamesResponse["results"]
    | ListPpbAppleCampaignNamesResponse["results"];

export type RubyChannelUpdateResponse =
    | UpdateAppleSearchResultsChannelResponse
    | UpdateAppleSearchTabsChannelResponse
    | UpdateAppleTodayTabChannelResponse
    | UpdateAppleProductPageBrowseChannelResponse;

export type RubyTargetingDimensions = RubyChannel["targetingDimensions"];
export type RubyDeviceClass = RubyTargetingDimensions["deviceClass"]["included"][number];
export type RubyGender = RubyTargetingDimensions["gender"]["included"][number];

export type RubyIOSAppOptions = AppleApplication;
export type RubyIOSAppSearchResult = AppleiTunesSearchResult;

export type RubyCampaignAppleStatus = GetAppleStatusResponse;
export interface RubyCampaignSynchronisation extends AppleChannelSynchronisationResponse {
    specification?: {
        final?: {
            issues?: string[];
            campaign?: {
                issues?: string[];
            };
        };
        desired?: object;
        baseline?: object;
        delta?: object;
    };
}

export interface RubyIOSApp extends RubyIOSAppSearchResult {
    countries: RubyIOSAppOptions["countries"];
}

export interface RubyProductPageLocale extends AppleProxyProductPageLocaleDetail {
    countryCode?: RubyCountry;
}

export interface RubyProductPage extends AppleProxyProductPageDetail {
    locales: RubyProductPageLocale[];
}

export interface RubyIOSProductPageQuery {
    campaignId: RubyCampaignId;
    teamId: RubyTeamId;
}

export type RubyASRRegion = GetAppleSearchResultsRegionResponse;
export type RubyASTRegion = GetAppleSearchTabsRegionResponse;
export type RubyATTRegion = GetAppleTodayTabRegionResponse;
export type RubyPPBRegion = GetAppleProductPageBrowseRegionResponse;
export type RubyRegion = RubyASRRegion | RubyASTRegion | RubyATTRegion | RubyPPBRegion;
export type RubyRegionId = RubyRegion["id"];

// Create Region
export type RubyCreateASRRegionBody = CreateAppleSearchResultsRegionBody;
export type RubyCreateASTRegionBody = CreateAppleSearchTabsRegionBody;
export type RubyCreateATTRegionBody = CreateAppleTodayTabRegionBody;
export type RubyCreatePPBRegionBody = CreateAppleProductPageBrowseRegionBody;
export type RubyCreateRegionBody =
    | RubyCreateASRRegionBody
    | RubyCreateASTRegionBody
    | RubyCreateATTRegionBody
    | RubyCreatePPBRegionBody;

export type RubyCreateASRRegionResponse = CreateAppleSearchResultsRegionResponse;
export type RubyCreateASTRegionResponse = CreateAppleSearchTabsRegionResponse;
export type RubyCreateATTRegionResponse = CreateAppleTodayTabRegionResponse;
export type RubyCreatePPBRegionResponse = CreateAppleProductPageBrowseRegionResponse;
export type RubyCreateRegionResponse =
    | RubyCreateASRRegionResponse
    | RubyCreateASTRegionResponse
    | RubyCreateATTRegionResponse
    | RubyCreatePPBRegionResponse;

// Update Region
export interface RubyUpdateASRRegionBody extends UpdateAppleSearchResultsRegionBody {
    id: RubyRegionId;
}
export interface RubyUpdateASTRegionBody extends UpdateAppleSearchTabsRegionBody {
    id: RubyRegionId;
}
export interface RubyUpdateATTRegionBody extends UpdateAppleTodayTabRegionBody {
    id: RubyRegionId;
}
export interface RubyUpdatePPBRegionBody extends UpdateAppleProductPageBrowseRegionBody {
    id: RubyRegionId;
}
export type RubyUpdateRegionBody =
    | RubyUpdateASRRegionBody
    | RubyUpdateASTRegionBody
    | RubyUpdateATTRegionBody
    | RubyUpdatePPBRegionBody;

export type RubyUpdateASRRegionResponse = UpdateAppleSearchResultsRegionResponse;
export type RubyUpdateASTRegionResponse = UpdateAppleSearchTabsRegionResponse;
export type RubyUpdateATTRegionResponse = UpdateAppleTodayTabRegionResponse;
export type RubyUpdatePPBRegionResponse = UpdateAppleProductPageBrowseRegionResponse;
export type RubyUpdateRegionResponse =
    | RubyUpdateASRRegionResponse
    | RubyUpdateASTRegionResponse
    | RubyUpdateATTRegionResponse
    | RubyUpdatePPBRegionResponse;

export type RubyBasicBudgetPlan = AllocatedBudgetPlanResponse;
export interface RubyBudgetPlan extends RubyBasicBudgetPlan {
    active: boolean;
    lengthInDays: number;
    daysRemaining: number;
}
export type RubyForcedRegionAllocation = DripBudgetBody["details"]["regions"][number];
export type RubyBudgetAllocation = ListBudgetAllocationsResponse["results"][number];

export type RubyCountry = ReplicaCampaignReport["country"];
export type RubyCurrency = RubyTeam["currency"];

type ReplicaReportSummaryValues = ReplicaReportSummaryResponse["totals"];
export type RubyMetricsReport = ReplicaReportSummaryValues;

export interface RubyMetricsReportQuery extends ReplicaCampaignReportSummaryQuery {
    teamId: RubyTeamId;
}

export interface RubySearchTermReportQuery extends RubyMetricsReportQuery {
    purpose?: Purpose[];
    keywordId?: RubyKeywordId[];
}

export interface RubyMetricsReportGrouping extends ReplicaReportGrouping {
    value: RubyMetricsReport;
}

export type RubyPaymentCard = UpdateDefaultStripePaymentMethodResponse;

export type RubyKeyword = GetAppleTargetingKeywordResponse;
export type RubyKeywordAssignment = RubyKeyword["assignments"][number];

export type RubyTargetingKeywordBidsInfo = ReplicaAdGroupTargetingKeywordBidsInfo;

export interface RubyRegionsReportQuery extends Omit<ReplicaCampaignReportGroupingQuery, "by"> {
    teamId: RubyTeamId;
}

type ReplicaReportGroupingGroups = ReplicaReportGroupingResponse["groups"];
export type RubyRegionsReport = ReplicaReportGroupingGroups;

export type RubyKeywordId = RubyKeyword["id"];

export interface RubyKeywordReportQuery extends Omit<ReplicaSearchTermReportSummaryQuery, "keywordId"> {
    teamId: RubyTeamId;
    keywordId: RubyKeywordId;
}

export type RubyHourlyBoost = RubyASRRegion["cpdDetails"]["hourlyBoost"];

// Share of Voice
export type RubyKeywordRankReport = ReplicaCustomReportResponse["results"][number];
export interface RubyKeywordRankQuery extends Omit<ReplicaCustomReportQuery, "take" | "skip" | "country"> {
    teamId: RubyTeamId;
    campaignId: RubyCampaignId;
    country: RubyCountry[];
}

// Events

export type RubyEventsCount = CountsResponse["counts"];

// We omit campaignId and name so we can restrict to single values in the UI (API support arrays)
export interface RubyEventQuery extends Omit<AttributionEventSummaryQuery, "campaignId" | "name"> {
    teamId: RubyTeamId;
    campaignId: RubyCampaignId;
    name: string;
}

export interface RubyEventSeriesQuery extends RubyEventQuery {
    granularity: ReportGranularity;
}

export type RubyEventSummaryReport = AttributionEventSummaryResponse;
export type RubyEventGroupingReport = AttributionEventGroupingResponse["groups"][number];
export type RubyNotificationSettings = GetUserNotificationSettingsResponse;

export type RubyOrder = CreatePrepayOrderResponse;

// History records

export type RubyHistoryRecordQuery = Omit<
    ListTeamRecordQuery | ListCampaignRecordQuery | ListUserRecordQuery,
    "skip" | "take"
>;
export interface RubyTeamHistoryRecordQuery extends RubyHistoryRecordQuery {
    teamId: RubyTeamId;
}
export interface RubyCampaignHistoryRecordQuery extends RubyHistoryRecordQuery {
    campaignId: RubyCampaignId;
}
export interface RubyUserHistoryRecordQuery extends RubyHistoryRecordQuery {
    userId: RubyUser["id"];
}

export type RubyCampaignRecord = CampaignRecord;
export type RubyTeamRecord = TeamRecord;
export type RubyUserRecord = UserRecord;
export type RubyHistoryRecord = CampaignRecord | TeamRecord | UserRecord;

// Rules
export type RubyRuleType = RuleType;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RubyRuleType = RuleType;

export type RubyRuleProperty = RuleProperty;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RubyRuleProperty = RuleProperty;

export type RubyRuleCondition = RuleCondition;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RubyRuleCondition = RuleCondition;

export type RubyRuleAction = RuleAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RubyRuleAction = RuleAction;

export interface RubyCampaignRule extends CampaignRuleResponse {
    type: RuleType.CAMPAIGN;
}
export interface RubyRegionRule extends RegionRuleResponse {
    type: RuleType.REGION;
}
export interface RubyKeywordRule extends KeywordRuleResponse {
    type: RuleType.KEYWORD;
}
export type RubyRule = RubyCampaignRule | RubyRegionRule | RubyKeywordRule;

export interface RubyRuleCreateBody extends CreateRuleBody {
    type: RubyRuleType;
    regionIds?: RubyRegionId[];
    excludedRegionIds?: RubyRegionId[];
    keywordIds?: RubyKeywordId[];
    excludedKeywordIds?: RubyKeywordId[];
}
export interface RubyRuleUpdateBody extends RubyRuleCreateBody {
    id: RubyRule["id"];
}
export type RubyUpdateRulePriorityBody = UpdateRulePriorityBody;

export type RubyChatMessage = OpenAIChatCompletionRequest["chat"][number];

export type RubyCampaignListingData = SortedCampaignListType;

export type RubyEgressTeamMessage = EgressTeamMessage;

export type RubyAppleCampaignsWithOrg = AppleCampaignsWithOrg;

export type RubyImportStandaloneCampaignBody = ImportStandaloneCampaignBody;

export type RubyImportStandaloneCampaignResponse = ImportStandaloneCampaignResponse;

export type RubyTargetingKeywordHistory = AsrTargetingKeywordHistoryResponse["results"][number];
