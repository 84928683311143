import type { AsyncData, RequestState } from "@/reducers/domain";
import { type UseMutateAsyncFunction, type useMutation, type useQuery } from "@tanstack/react-query";

export function useQueryAsAsyncData<A, B, C, D extends readonly unknown[]>(
    query: ReturnType<typeof useQuery<A, B, C, D>>
): AsyncData<C> {
    const { data, error, isSuccess, isLoading, dataUpdatedAt } = query;
    return {
        isRequesting: isLoading,
        success: isSuccess,
        errorMessage: error as string,
        lastUpdated: dataUpdatedAt,
        data,
    };
}

export type MutationRequestState<A, B, C, D> = RequestState & { request: UseMutateAsyncFunction<A, B, C, D> };

export function useMutationAsRequestState<A, B, C, D>(
    mutation: ReturnType<typeof useMutation<A, B, C, D>>
): MutationRequestState<A, B, C, D> {
    const { isLoading, isSuccess, error, mutateAsync } = mutation;
    return {
        isRequesting: isLoading,
        success: isSuccess,
        errorMessage: error as string,
        request: mutateAsync,
    };
}
