import { CAMPAIGN_CARD_DIAL_SIZE } from "@/components/assemblies/campaignCard/constants";
import { type RubyASRChannel, type RubyCampaignId } from "../../../../../services/backend/RubyData";
import { currencyFormatter } from "../../../../../utilities/currency";
import { aggregateAsyncData } from "../../../../../utilities/requests";
import { languageString } from "../../../../../utilities/text";
import { useCampaignDataLoader } from "../../../../assemblies/campaignDataLoader/CampaignDataLoader";
import RequestLoader from "../../../../blocks/requestLoader/RequestLoader";
import StatRadialTarget from "../../../../blocks/stats/radialTarget/StatRadialTarget";

interface CostPerDownloadDialProps {
    campaignId?: RubyCampaignId;
    size?: number;
}

export default function CostPerDownloadDial({ size = CAMPAIGN_CARD_DIAL_SIZE, campaignId }: CostPerDownloadDialProps) {
    const loader = useCampaignDataLoader(campaignId);
    const currency = loader.useCurrency();
    const channel = loader.useChannel();
    const metrics = loader.useMetricsSummary();

    const formatCurrency = currencyFormatter(currency);

    return (
        <div className="u-relative">
            <RequestLoader
                request={aggregateAsyncData(metrics, channel)}
                placeholder={
                    <StatRadialTarget
                        label={languageString("campaign.listing.cpdTarget.title")}
                        value={0}
                        target={0}
                        formatter={formatCurrency}
                        negativeIsGood
                        size={size}
                    />
                }
                fallback={
                    <div className="u-fade2">
                        <StatRadialTarget
                            label={languageString("campaign.listing.cpdTarget.title")}
                            value={0}
                            target={0}
                            formatter={formatCurrency}
                            negativeIsGood
                            size={size}
                        />
                    </div>
                }
            >
                <StatRadialTarget
                    label={languageString("campaign.listing.cpdTarget.title")}
                    hint={languageString("campaign.listing.cpdTarget.description")}
                    value={metrics.data?.costPerInstall ?? 0}
                    target={(channel.data as RubyASRChannel)?.cpdGoal}
                    formatter={formatCurrency}
                    negativeIsGood
                    size={size}
                    subLabel={languageString("campaign.listing.cpdTarget.target", "", [
                        formatCurrency((channel.data as RubyASRChannel)?.cpdGoal),
                    ])}
                />
            </RequestLoader>
        </div>
    );
}
