import { rubyService } from "@/services";
import type { RubyCampaignId, RubyKeywordId, RubyTeamId } from "@/services/backend/RubyData";
import { useQuery } from "@tanstack/react-query";
import { useQueryAsAsyncData } from "./lib";

const KEYWORDS_KEY = "KEYWORDS";

type KeywordMetricsPayload = {
    teamId: RubyTeamId;
    campaignId: RubyCampaignId;
    keywordId: RubyKeywordId;
    from: number;
    to: number;
};

export function useTargetingKeywordHistory(payload: KeywordMetricsPayload) {
    return useQueryAsAsyncData(
        useQuery({
            queryKey: [KEYWORDS_KEY, payload] as const,
            queryFn: async ({ queryKey }) => {
                const { teamId, campaignId, keywordId, from, to } = queryKey[1];

                const history = await rubyService.appleChannels.listAsrChannelTargetingKeywordHistory(
                    {
                        teamId,
                        campaignId,
                        keywordId,
                    },
                    {
                        from,
                        to,
                    },
                    {
                        all: true,
                    }
                );

                return history.results;
            },
            enabled: !!payload.campaignId && !!payload.teamId,
        })
    );
}
